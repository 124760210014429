const config = {
  style: {
    version: 8,
    // name: `Naturkartan`,
    // metadata: {
    //   "mapbox:autocomposite": true,
    //   "mapbox:type": `template`,
    //   "mapbox:sdk-support": { js: `0.50.0`, android: `6.7.0`, ios: `4.6.0` },
    //   "mapbox:groups": {},
    // },
    // center: [36.8206053944314, 0.010353187956283705],
    // zoom: 22,
    // bearing: 0,
    // pitch: 0,
    sources: {},
    sprite: `${process.env.URL}/sprite/sprite-tmp`,
    glyphs: `${process.env.URL}/glyphs/{fontstack}/{range}.pbf`,
    // glyphs: `mapbox://fonts/mapbox/{fontstack}/{range}.pbf`,
    layers: [],
    // created: `2019-02-01T15:14:14.553Z`,
    // modified: `2023-08-28T11:50:28.800Z`,
    // id: `cjrm6vq133i9o2snvbz15j1es`,
    // owner: `outdoormap`,
    // visibility: `private`,
    // protected: false,
    // draft: false,
  },
}

export default config
